import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AddForm } from './components/common/AddForm';
import { Header } from './components/layout/Header';
import { Map } from './components/Map/Map';
import { SearchContext } from './contexts/searchContext';
import './sass/_App.scss';


export const App = () => {

  const [search, setSearch] = useState<string>('');

  return (

    <div className="app__wrapper">
      <SearchContext.Provider value={{
        search,
        setSearch
      }}>
        <Header />
        <Routes>
          <Route path='/' element={<Map />} />
          <Route path='/add' element={<AddForm />} />
        </Routes>

      </SearchContext.Provider>

    </div>

  );
}

