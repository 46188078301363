import { SyntheticEvent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchContext } from '../../contexts/searchContext';
import { Button } from '../common/Button';
import './Header.scss';

export const Header = () => {

  const [inputVal, setInputVal] = useState('');
  const { setSearch } = useContext(SearchContext);

  const setSearchFromLocalState = (e: SyntheticEvent) => {
    e.preventDefault();
    setSearch(inputVal);
    setInputVal('');
  }

  return (
    <>
      <header className=''>
        <Link className='' to="/">
          <h1><strong>Mega</strong> ogłoszenia</h1>

        </Link>
        <Button to="/add" content={'Dodaj ogłoszenie'} />
        <form className="search" onSubmit={setSearchFromLocalState}>
          <input type="text" name="" id="" value={inputVal} onChange={(e) => setInputVal(String(e.target.value))} />
          <Button type={'submit'} content={'Szukaj'} />
        </form>
      </header>
    </>
  )
}