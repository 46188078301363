
import './Spinner.css'


export const Spinner = () => {


  return (
    <>
      <div className="Spinner loader"></div>
    </>
  )
}