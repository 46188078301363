
import { Link } from 'react-router-dom'
import './Button.scss'

interface Props {
  type?: "button" | "submit" | "reset" | undefined,
  content: string
  to?: string
}

export const Button = ({ type, content, to }: Props) => {

  return (
    <>
      {
        to ? <Link className='btn' to={to}>{content}</Link>
          :
          <button type={type}>{content}</button>
      }
    </>
  )
}