import 'leaflet/dist/leaflet.css';
import { useContext, useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { SimpleAdEntity } from 'types';
import { apiUrl } from '../../config/api';
import { SearchContext } from '../../contexts/searchContext';
import '../../utils/fix-map-icon';
import './Map.scss';
import { SingleAd } from './SingleAd';


export const Map = () => {

  const { search } = useContext(SearchContext);
  const [ads, setAds] = useState<SimpleAdEntity[]>([]);

  useEffect(() => {
    (async () => {
      const res = await fetch(`${apiUrl}/ad/search/`);
      const data = await res.json();

      setAds(data)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const res = await fetch(`${apiUrl}/ad/search/${search}`);
      const data = await res.json();

      setAds(data)
    })()
  }, [search])


  return (
    <>
      <div className="map">

        <MapContainer center={[52.237049, 21.017532]} zoom={6}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> & contributors"
          />

          {
            ads.map(ad => (
              <Marker key={ad.id} position={[ad.lat, ad.lon]} >
                <Popup>
                  <SingleAd id={ad.id} />
                </Popup>
              </Marker>
            ))
          }
        </MapContainer>
      </div>
    </>
  )
}