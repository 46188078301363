import { createContext } from "react";

interface SearchContextType {
  search: string,
  setSearch: (s: string) => void,
}

export const SearchContext = createContext<SearchContextType>({
  search: "",
  setSearch: (s: string) => { },
});