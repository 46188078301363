
export const geocode = async (address: String): Promise<{
  lat: number,
  lon: number
}> => {

  const geoRes = await fetch(`https://nominatim.openstreetmap.org/search/${address}?format=json&addressdetails=1&limit=1&polygon_svg=1`);

  const geoData = await geoRes.json();

  return {
    lat: Number(geoData[0].lat),
    lon: Number(geoData[0].lon),
  }
}