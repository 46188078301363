import { ChangeEvent, FormEvent, useState } from 'react';
import { NewAdEntity } from 'types';
import { apiUrl } from '../../config/api';
import { geocode } from '../../utils/geocoding';
import './AddForm.scss';
import { Button } from './Button';
import { Spinner } from './Spinner';


export const AddForm = () => {

  const [loading, setLoading] = useState<boolean>(false);

  const [address, setAddress] = useState("");

  const [form, setForm] = useState<NewAdEntity>({
    name: '',
    description: '',
    price: 0,
    url: '',
    lat: 0,
    lon: 0,
  })

  const [id, setId] = useState<string | null>(null);

  const [serverMessage, setServerMessage] = useState<string | null>(null);

  const formChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === 'number') {

      setForm(prev => ({
        ...prev,
        [e.target.name]: Number(e.target.value)
      }))
    }
    else {
      setForm(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }))
    }
  }

  const TextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {

    setForm(prev => ({
      ...prev,
      description: e.target.value
    }))
  }

  const formSend = async (e: FormEvent) => {
    e.preventDefault();

    setLoading(true);

    try {
      const { lat, lon } = await geocode(address)

      const res = await fetch(`${apiUrl}/ad`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...form,
          lat,
          lon
        })

      })

      const data = await res.json();

      setId(data.id);

      setServerMessage(data.message)

      // console.log(data);


    } finally {

      setLoading(false);

      setAddress('');
      setId(null);

      setForm({
        name: '',
        description: '',
        price: 0,
        url: '',
        lat: 0,
        lon: 0,
      })
    }

    setLoading(false);

  }

  if (loading) {
    return <Spinner />
  }

  if (id) {
    return (
      <>
        <h3>Ogłoszenie { } zostało dodane do serwisu pod ID {id}</h3>
        <button onClick={() => setId(null)}>Dodaj kolejne</button>
      </>

    )
  }

  if (serverMessage) {
    return (
      <>
        <h3>{serverMessage}</h3>
        <button onClick={() => setServerMessage(null)}>Ok</button>
      </>
    )
  }


  return (
    <>
      <form className='add-form' onSubmit={formSend}>
        <h1>Dodawanie ogłoszenia</h1>
        <div className="add-form__input__wrapper">
          <p><label >
            Nazwa: <br />
            <input type="text" name="name" value={form.name} onChange={formChange} maxLength={99} required />
          </label>
          </p>
          <p><label >
            Opis: <br />
            <textarea name='description' maxLength={999} value={form.description} onChange={TextAreaChange} />
          </label>
          </p>

          <p><label >
            Cena: <br />
            <input type="number" name="price" value={form.price} onChange={formChange} maxLength={10} required />
          </label>
          </p>

          <p><label >
            Adres URL: <br />
            <input type="url" name="url" value={form.url} onChange={formChange} />
          </label>
          </p>

          <p><label >
            Adress fizyczny: <br />
            <input type="text"
              name="address"
              value={address}
              onChange={e => setAddress(e.target.value)}
              required />
          </label>
          </p>

          <Button type='submit' content={'Dodaj'} />
        </div>

      </form>
    </>
  )
}